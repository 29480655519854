import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";

import { useAuth } from "./AuthProvider";

import { TimerContext } from "./timerContext_new";

import { Elements } from "@stripe/react-stripe-js";
import PaymentPopup from "./popUps/paymentPopup";

import fondo2 from "../imgs/cuartos/asesinoimitador/fondo2.webp";
import mixtape from "../imgs/cuartos/asesinoimitador/mixtape.webp";
import papel1 from "../imgs/cuartos/asesinoimitador/papel1.png";
import timeRedIcon from "../imgs/timer-red.svg";

import { stripePromise } from "../stripeConfig";

const AsesinoImitador = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [backgroundSize, setBackgroundSize] = useState("cover");
  const [error, setError] = useState("");
  const [hasPaid, setHasPaid] = useState(false);
  const navigate = useNavigate();
  const { timeLeft } = useContext(TimerContext);

  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const verifyClave = async (clave) => {
    if (clave === "Aspen, Hopkins") {
      try {
        if (currentUser) {
          // Referencia al documento del usuario en la colección correspondiente
          const userDocRef = doc(db, "asesino_imitador", currentUser.uid);

          // Actualizar la currentPhase a 3
          await updateDoc(userDocRef, { currentPhase: 3 });
          console.log("Fase actualizada a 3 en Firestore");
        }

        setTimeout(() => {
          setShowPopup(false);
          navigate("/casainterior");
        }, 3000); // Esperar 3 segundos antes de redirigir
      } catch (error) {
        console.error("Error al actualizar la fase:", error);
        setError("Hubo un problema al actualizar la fase. Intenta de nuevo.");
      }
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setBackgroundSize("100% 200px");
      } else {
        setBackgroundSize("100% 150px");
      }
    };

    // Verificar si el usuario ha pagado
    const checkPaymentStatus = async () => {
      if (currentUser) {
        const docRef = doc(db, "asesino_imitador", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const userHasPaid = data.hasPaid || false;
          setHasPaid(userHasPaid);
          setShowPaymentPopup(!userHasPaid);
        } else {
          setShowPaymentPopup(true);
        }
      }
    };

    checkPaymentStatus();

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentUser]);

  return (
    <div
      className="min-h-screen h-auto flex"
      style={{
        backgroundImage: `url(${fondo2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="m-auto my-6 p-6 h-auto rounded-lg bg-blueCTC/20 shadow-lg text-white mx-8">
        <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
        {showPaymentPopup && !hasPaid && (
          <Elements stripe={stripePromise}>
            <PaymentPopup
              onClose={() => setShowPaymentPopup(false)}
              collectionName="asesino_imitador"
              phaseNumber={2}
              amount={1}
              originalAmount={189}
            />
          </Elements>
        )}
        <div className="flex flex-col lg:flex-row md:space-x-4">
          <div className="w-full lg:w-1/2 flex flex-col items-center justify-center">
            <div className="bg-slate-50 p-4 text-black font-roboto-mono">
              <p className="text-sm md:text-base mb-4">
                <strong>
                  Han llegado al hotel donde se hospedaba el asesino, su trabajo
                  de deducción ha sido brillante, pero el individuo parece estar
                  "Siempre un paso adelante".
                </strong>
              </p>
              <p className="text-sm md:text-base mb-4">
                Al llegar, el conserje les entrega un paquete. <br />
                <em>—¿Qué es esto?</em> -preguntaron ustedes. <br />
                <em>
                  —"El Sr. Bundy me pidió que se los entregue cuando estaba
                  haciendo su checkout por la mañana".—
                </em>
              </p>
              <p className="text-sm md:text-base mb-4">
                El paquete contiene una nota escrita por el sospechoso:
              </p>
              <div className="flex items-center justify-center">
                <div className="bg-slate-200 p-4 rounded shadow">
                  <p className="text-sm md:text-base italic">
                    "Nos dejamos guiar más por nuestras convicciones que por lo
                    racional, porque la razón nos decía que era imposible".
                  </p>
                  <a
                    href="https://open._______.com/user/qmtc6gthqlnf6r9kidcn0qhho?si=hPxtLhsJT3-vwzqqfnN0Qg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm md:text-base text-green-500 underline"
                  >
                    https://open.________.com
                  </a>
                </div>
              </div>
            </div>
            <div
              className="text-black font-roboto-mono h-72 flex flex-col items-center justify-center p-4"
              style={{
                backgroundImage: `url(${papel1})`,
                backgroundSize: backgroundSize,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <p className="text-sm md:text-base mb-4">
                En el interior del paquete se encuentra un mixtape y un walkman.
                Pero ambos son demasiado antiguos para usar.
              </p>
            </div>
          </div>
          <div className="w-full lg:w-1/2 flex items-center justify-center">
            <img
              className="w-auto h-auto"
              src={mixtape}
              alt="Imagen del mixtape"
            />
          </div>
        </div>

        <ContinueButton setShowPopup={setShowPopup} />

        {showPopup && (
          <PasswordPopup
            onSubmit={verifyClave}
            onClose={() => setShowPopup(false)}
            titleText="Ingresa la dirección de la casa"
            placeholderText="Dirección"
            buttonText="Verificar dirección"
            error={error}
          />
        )}
      </div>
    </div>
  );
};

export default AsesinoImitador;
