import React, { useState, useContext } from "react";
import fondoClub42 from "../imgs/cuartos/club42/fondo_club42.webp";
import logoClub42 from "../imgs/cuartos/club42/logo.png";
import elevadorImage from "../imgs/cuartos/club42/elevador.webp";
import ContinueButtonGold from "./buttons/continueButtonGold";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";
import infoImage from "../imgs/cuartos/club42/info.png";
import answerImage from "../imgs/cuartos/club42/answer.webp";
import PasswordPopup from "./popUps/passwordPopup";
import SuccessPopup from "./popUps/successPopup";
import diploma from "../imgs/diplomas/Diplomas_club-42.webp";

const Elevador = () => {
  const { timeLeft, stopTimer } = useContext(TimerContext);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState("");

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const calculateTimeElapsed = () => {
    const totalTime = 60 * 60; // 3600 segundos (60 minutos)
    const timeElapsedInSeconds = totalTime - timeLeft;
    return formatTime(timeElapsedInSeconds);
  };

  const verifyClave = (clave) => {
    if (clave === "2231") {
      stopTimer();
      setShowPasswordPopup(false);
      setShowSuccessPopup(true);
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center text-white py-12"
      style={{
        backgroundImage: `url(${fondoClub42})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Logo */}
      <div className="my-12">
        <img src={logoClub42} alt="Club 42 Logo" className="w-48 h-auto" />
      </div>

      {/* Texto principal */}
      <div className="text-center px-8">
        <p className="text-xl md:text-2xl mb-4">
          Llegaste al elevador indicado, como puedes ver, nuestro lounge no está
          anunciado para el público general.
        </p>
        <p className="text-xl md:text-2xl mb-4">
          Este elevador está programado para desbloquear nuestro piso únicamente
          al presionar los botones <span className="font-bold">EN</span> una
          secuencia específica.
        </p>
        <p className="text-lg italic mb-6">
          Para encontrarla resuelve el siguiente acertijo:
        </p>
      </div>

      {/* Imágenes principales */}
      <div className="flex flex-col lg:flex-row items-center justify-center space-y-4 lg:space-y-0 lg:space-x-4 mb-8">
        {/* Imagen del elevador */}
        <img
          src={elevadorImage}
          alt="Elevador"
          className="w-full max-w-sm h-auto"
        />
        {/* Imagen de información */}
        <img
          src={infoImage}
          alt="Información"
          className="w-full max-w-sm h-auto"
        />
      </div>

      {/* Imagen de respuesta */}
      <div className="my-8 flex flex-col items-center justify-center">
        <p className="mb-4">Secuencia:</p>
        <img src={answerImage} alt="Respuesta" className="max-w-md h-auto" />
      </div>

      {/* Botón de continuar */}
      <ContinueButtonGold setShowPopup={() => setShowPasswordPopup(true)} />

      {/* Pop-up para ingresar la clave */}
      {showPasswordPopup && (
        <PasswordPopup
          onSubmit={verifyClave}
          onClose={() => setShowPasswordPopup(false)}
          titleText="Ingresa la Secuencia del Elevador"
          placeholderText="Secuencia"
          buttonText="Verificar secuencia"
          error={error}
        />
      )}

      {/* Pop-up de felicitaciones si la clave es correcta */}
      {showSuccessPopup && (
        <SuccessPopup
          onClose={() => setShowSuccessPopup(false)}
          timeElapsed={calculateTimeElapsed()}
          imageUrl={diploma}
          message={"¡Felicidades! Has desbloqueado el elevador."}
          roomId="club_42"
        />
      )}
    </div>
  );
};

export default Elevador;
