import React, { createContext, useState, useEffect, useRef } from "react";

// Crear el contexto para el temporizador
export const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState(60 * 60); // 60 minutos en segundos
  const timerRef = useRef(null); // Referencia para guardar el temporizador

  // Función para iniciar el temporizador
  const startTimer = () => {
    // Si ya hay un temporizador corriendo, lo limpiamos primero para evitar duplicados
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    // Guardar la hora actual como el inicio del temporizador en localStorage
    const startTime = localStorage.getItem("timerStartTime");

    if (!startTime) {
      localStorage.setItem("timerStartTime", Date.now());
    }

    // Inicia el temporizador
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 0) {
          clearInterval(timerRef.current); // Detén el temporizador si llega a 0
          localStorage.removeItem("timerStartTime");
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Calcular el tiempo restante al montar el componente
  useEffect(() => {
    const startTime = localStorage.getItem("timerStartTime");
    if (startTime) {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000); // Tiempo transcurrido en segundos
      const initialTimeLeft = 60 * 60 - elapsedTime;
      setTimeLeft(initialTimeLeft > 0 ? initialTimeLeft : 0);

      if (initialTimeLeft > 0) {
        startTimer();
      }
    } else {
      startTimer();
    }

    return () => clearInterval(timerRef.current);
  }, []);

  // Función para detener el temporizador
  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  // Función para reiniciar el temporizador
  const restartTimer = (seconds = 60 * 60) => {
    // Por defecto, 60 minutos
    stopTimer();
    localStorage.removeItem("timerStartTime");
    setTimeLeft(seconds);
    startTimer();
  };

  return (
    <TimerContext.Provider
      value={{ timeLeft, stopTimer, restartTimer, startTimer }}
    >
      {children}
    </TimerContext.Provider>
  );
};
