import React, { useState, useContext } from "react";
import PasswordPopup from "./popUps/passwordPopup"; // Importa el popup de la contraseña
import SuccessPopup from "./popUps/successPopup"; // Importa el popup de éxito
import { TimerContext } from "./timerContext_new"; // Importa el contexto del temporizador

import washington from "../imgs/cuartos/BigBrother/washington.webp"; // Importa la imagen de Washington

import continueIcon from "../imgs/continue_white.svg"; // Importa el icono de continuar
import timeRedIcon from "../imgs/timer-red.svg"; // Importa el icono del temporizador

import diploma from "../imgs/diplomas/Diplomas_big-brother.webp";

const PuntoPuntoPunto = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [setIsPasswordCorrect] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // Estado para manejar el popup de éxito
  const [error, setError] = useState(""); // Estado para manejar errores

  const { timeLeft, stopTimer } = useContext(TimerContext); // Accede al tiempo restante y la función para detener el temporizador

  // Función para formatear el tiempo
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // Calcula el tiempo que se ha tardado
  const calculateTimeElapsed = () => {
    const totalTime = 60 * 60; // 3600 segundos (60 minutos)
    const timeElapsedInSeconds = totalTime - timeLeft;
    return formatTime(timeElapsedInSeconds); // Devuelve el tiempo transcurrido formateado
  };

  // Función que maneja el envío de la contraseña
  const handlePasswordSubmit = (password) => {
    if (password === "Krai de Krasnoyarsk") {
      // Clave correcta
      stopTimer(); // Detiene el temporizador
      setIsPopUpOpen(false);
      setShowSuccessPopup(true); // Muestra el popup de éxito
      setError(""); // Limpia el error
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  const handleContinueClick = () => {
    setIsPopUpOpen(true); // Abre el PopUp cuando se hace clic en "Continuar"
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-700">
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>
      <div className="classname">
        <img src={washington} alt="Washington" className="h-32" />
      </div>

      {/* Mostrar enlaces y el botón de continuar */}
      <div className="flex flex-col items-center mb-8">
        <p className="text-white text-2xl">https://youtu.be/IQiVwzTzAcQ</p>
        <p className="text-white text-2xl">https://youtu.be/IQiVwzTz_cQ</p>
        <p className="text-white text-2xl">https://youtu.be/IQiVwzTzCcQ</p>
        <p className="text-white text-2xl">https://youtu.be/IQiVwzTzDcQ</p>
        <p className="text-white text-2xl">https://youtu.be/IQiVwzTzEcQ</p>
        <p className="text-white text-2xl">https://youtu.be/IQiVwzTzFcQ</p>
        <p className="text-white text-2xl">https://youtu.be/IQiVwzTzGcQ</p>
      </div>
      <button
        onClick={handleContinueClick}
        className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center justify-center"
      >
        <img src={continueIcon} alt="Continue" className="h-6 w-6 inline" />
        Continuar
      </button>

      {isPopUpOpen && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit} // Verificar la contraseña
          onClose={() => setIsPopUpOpen(false)} // Cerrar el PopUp
          error={error}
          titleText="Ingresa la ubicación del complejo nuclear"
          placeholderText="Nombre de la isla"
          buttonText="Verificar ubicación"
        />
      )}
      {showSuccessPopup && (
        <SuccessPopup
          onClose={() => setShowSuccessPopup(false)}
          timeElapsed={calculateTimeElapsed()}
          imageUrl={diploma}
          message={"¡Felicitaciones! Has desactivado la bomba."}
          roomId="big_brother"
        />
      )}
    </div>
  );
};

export default PuntoPuntoPunto;
