import React, { useState, useEffect, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../AuthProvider";
import stripelogo from "../../imgs/stripe_logo.png";
import paypal_logo from "../../imgs/paypal_logo.png";
import { TimerContext } from "../timerContext_new";

const PaymentPopup = ({
  onClose,
  navigate,
  collectionName,
  phaseNumber,
  amount,
  originalAmount,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const { currentUser } = useAuth();
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isValidatingCoupon, setIsValidatingCoupon] = useState(false);
  const [couponError, setCouponError] = useState(null);
  const [finalAmount, setFinalAmount] = useState(amount);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [showPayPalForm, setShowPayPalForm] = useState(false);

  const { restartTimer } = useContext(TimerContext);

  useEffect(() => {
    if (stripe) {
      const mode = stripe._apiKey.startsWith("pk_live_")
        ? "LIVE/PRODUCTION"
        : "TEST";
      console.log("=== STRIPE MODE ===");
      console.log("Frontend Stripe Mode:", mode);
      console.log("Stripe Key:", stripe._apiKey.substring(0, 12) + "...");
      console.log("==================");
    }
  }, [stripe]);

  const updateProgressInFirestore = async (userUID) => {
    const userDocRef = doc(db, collectionName, userUID);

    try {
      await setDoc(
        userDocRef,
        {
          uid: userUID,
          hasPaid: true,
          currentPhase: phaseNumber,
        },
        { merge: true }
      );
      console.log(`Progreso en ${collectionName} actualizado.`);
    } catch (error) {
      console.error("Error al actualizar el progreso en Firestore:", error);
    }
  };

  const validateCoupon = async () => {
    setIsValidatingCoupon(true);
    setCouponError(null);

    try {
      const { data } = await axios.post(
        "https://ctc-web-qa7a.onrender.com/api/validate-coupon",
        { code: couponCode }
      );

      if (data.valid) {
        setDiscount(data.discount);
        const discountedAmount = Number(
          (amount - (amount * data.discount) / 100).toFixed(2)
        );
        setFinalAmount(discountedAmount);
      } else {
        setCouponError("Cupón inválido");
        setFinalAmount(amount);
      }
    } catch (error) {
      setCouponError("Error al validar el cupón");
      setFinalAmount(amount);
    } finally {
      setIsValidatingCoupon(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setError(null);

    try {
      const { data } = await axios.post(
        "https://ctc-web-qa7a.onrender.com/api/create-payment-intent",
        {
          amount: finalAmount * 100,
          couponCode: couponCode,
        }
      );

      const { clientSecret } = data;
      console.log("Client Secret recibido:", clientSecret);

      if (!clientSecret) {
        throw new Error("No se pudo obtener el Client Secret del servidor.");
      }

      const cardElement = elements.getElement(CardElement);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      console.log("Resultado de confirmCardPayment:", result);

      if (result.error) {
        console.error("Error en el pago:", result.error);
        setError(
          result.error.message || "Error desconocido al procesar el pago."
        );
        setIsProcessing(false);
      } else if (result.paymentIntent.status === "succeeded") {
        console.log("¡Pago exitoso!");
        await updateProgressInFirestore(currentUser.uid);
        restartTimer(40 * 60);
        setPaymentSuccess(true);
        setTimeout(onClose, 3000);
      }
    } catch (err) {
      console.error("Error completo:", err);
      setError("Error al comunicarse con el servidor. Intenta nuevamente.");
      setIsProcessing(false);
    }
  };

  const handleStripeClick = () => {
    setShowStripeForm(true);
    setShowPayPalForm(false);
  };

  const handlePayPalClick = () => {
    setShowPayPalForm(true);
    setShowStripeForm(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AVvModcE5__rXzeydmnn89Au2G_owtPuIaUlcZMzH4x7h-YUSskeLzDmBYfgsKkspIqCA3jEX9hheXWQ&currency=MXN";
    script.addEventListener("load", () => {
      if (window.paypal) {
        console.log("PayPal SDK cargado correctamente.");
      }
    });
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (showPayPalForm && window.paypal) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: finalAmount.toString(),
                    currency_code: "MXN",
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            return actions.order.capture().then(async (details) => {
              console.log(
                "Pago completado por:",
                details.payer.name.given_name
              );
              await updateProgressInFirestore(currentUser.uid);
              restartTimer(40 * 60);
              setPaymentSuccess(true);
              setTimeout(onClose, 3000);
            });
          },
          onError: (err) => {
            console.error("Error en el pago de PayPal:", err);
            setError(
              "Error al procesar el pago con PayPal. Intenta nuevamente."
            );
          },
        })
        .render("#paypal-button-container");
    }
  }, [showPayPalForm]);

  const cardStyle = {
    style: {
      base: {
        color: "white",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div
        className={`relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow ${
          showPayPalForm || showStripeForm ? "scrollable-container" : ""
        }`}
      >
        {!showPayPalForm && !showStripeForm && (
          <>
            <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
              Atención...
            </h2>
            <p className="text-white mb-4">
              Ha concluido tu prueba gratuita. Todavía queda mucho más por
              descubrir! Si has llegado hasta este punto, estamos seguros que te
              va a encantar todo lo que queda por descifrar
            </p>
            <p className="text-white mb-4">
              Al pagar el acceso al cuarto completo obtendrás lo siguiente:
            </p>
            <ul className="text-white mb-4 list-none pl-0">
              <li>- Acceso completo a todas las pistas del cuarto.</li>
              <li>- Temporizador reiniciado de 40 minutos.</li>
              <li>
                - Insignia virtual coleccionable, personalizada con tu tiempo
                final.
              </li>
              <li>- Oportunidad de participar en promociones especiales.</li>
              <li>- Experiencia sin publicidad.</li>
            </ul>
            <p className="text-white mb-4">
              No dejes de ponerte a prueba, o será que no te crees capaz de
              terminarlo...
            </p>
          </>
        )}
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          {paymentSuccess ? (
            "Pago Exitoso!"
          ) : (
            <div className="flex items-center space-x-2">
              <span className="text-white">Pago</span>
              <span className="text-white">${finalAmount}</span>
              <span className="text-gray-500 line-through">
                ${originalAmount}
              </span>
            </div>
          )}
        </h2>
        {!paymentSuccess ? (
          <div>
            <div className="flex justify-between mb-4 space-x-2">
              <button
                className="bg-redCTC text-white font-bold px-4 py-2 rounded-md hover:bg-primary/80 flex-1"
                onClick={handleStripeClick}
              >
                Pago con Tarjeta
              </button>
              <button
                className="bg-[#0079c1] text-white px-4 py-2 rounded-md hover:bg-blue-500 flex items-center justify-center flex-1"
                onClick={handlePayPalClick}
              >
                <img
                  src={paypal_logo}
                  alt="Logo de PayPal"
                  className="inline-block h-6"
                />
              </button>
            </div>
            {showStripeForm && (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      placeholder="Código de cupón"
                      className="flex-1 px-3 py-2 rounded-md bg-gray-700 text-white"
                    />
                    <button
                      type="button"
                      onClick={validateCoupon}
                      disabled={isValidatingCoupon || !couponCode}
                      className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-500"
                    >
                      {isValidatingCoupon ? "Validando..." : "Aplicar"}
                    </button>
                  </div>
                  {couponError && (
                    <p className="text-red-500 text-sm mt-1">{couponError}</p>
                  )}
                  {discount > 0 && (
                    <p className="text-green-500 text-sm mt-1">
                      ¡Cupón aplicado! Descuento: {discount}%
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <CardElement options={cardStyle} />
                </div>
                <div className="flex justify-between">
                  <button
                    className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center justify-center"
                    type="submit"
                    disabled={!stripe || isProcessing}
                  >
                    {isProcessing ? "Procesando..." : "Pagar"}
                  </button>
                  <button
                    className="text-white px-4 py-2 rounded-md bg-gray-500 flex items-center justify-center"
                    onClick={onClose}
                  >
                    No soy capaz
                  </button>
                </div>
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </form>
            )}
            {showPayPalForm && (
              <div id="paypal-button-container" className="mb-4"></div>
            )}
          </div>
        ) : (
          <p className="text-green-500 text-lg">
            ¡El pago fue procesado con éxito!
          </p>
        )}
        <p className="text-gray-400 text-sm mt-4 text-end">
          Powered by{" "}
          <img
            src={stripelogo}
            alt="Stripe Logo"
            className="inline-block h-4"
          />
        </p>
      </div>
    </div>
  );
};

export default PaymentPopup;
