// TermsPopup.js
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TimerContext } from "../timerContext_new";

const VirtualTermsPopup = ({ onAccept, onClose }) => {
  const { restartTimer } = useContext(TimerContext);

  const handleAccept = () => {
    restartTimer();
    onAccept();
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          Instrucciones
        </h2>
        <p className="text-white mb-4">
          1. Las pistas pueden estar escondidas en cualquier parte de la web.
          ¡No te limites!
        </p>
        <p className="text-white mb-4">
          2. Cuentas con 60 minutos para finalizar el cuarto, una vez iniciado
          el cronometro no se detendrá.
        </p>
        <p className="text-white mb-4">
          3. Te recomendamos utilizar computadora para tu comodidad, sin
          embargo, desde cualquier dispositivo lo podrás hacer.
        </p>
        <p className="text-white mb-4">
          4. No será fácil, solo los CRACKS encuentran la forma. ¿Eres uno de
          nosotros? ¡Demuéstralo!
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50"
            onClick={handleAccept}
          >
            Inicio
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50"
            onClick={onClose}
          >
            No soy capaz
          </button>
        </div>
        <div className="mt-4">
          <p className="text-white mb-4">
            Al dar click en inicio, aceptas los
            <Link
              to="/terms"
              target="_blank"
              className="text-blue-300 underline mx-1"
            >
              términos y condiciones.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VirtualTermsPopup;
