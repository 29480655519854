import React, { useState } from "react";
import { signInWithGoogle, registerWithEmailAndPassword } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function FormSignUp({ onSwitchToSignIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dayOfBirth, setDayOfBirth] = useState("");
  const [monthOfBirth, setMonthOfBirth] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [referralSource, setReferralSource] = useState("");

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await registerWithEmailAndPassword(
        email,
        password,
        firstName,
        lastName,
        dayOfBirth,
        monthOfBirth,
        yearOfBirth,
        gender,
        referralSource
      );
      alert("Usuario registrado con éxito");
      navigate("/");
    } catch (error) {
      console.error("Error al registrar el usuario:", error);
      if (error.code === "auth/email-already-in-use") {
        alert(
          "El correo electrónico ya está registrado. Por favor, inicia sesión."
        );
      } else {
        alert(
          "Hubo un error al registrar el usuario. Por favor, inténtalo de nuevo."
        );
      }
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      await signInWithGoogle(() => navigate("/"));
    } catch (error) {
      console.error("Error al registrarse con Google:", error);
      alert(
        "Hubo un error al registrarse con Google. Por favor, inténtalo de nuevo."
      );
    }
  };

  return (
    <form
      onSubmit={handleRegister}
      className="p-6 bg-blueCTC rounded-lg shadow-lg"
    >
      <h1 className="text-2xl text-white font-bold mb-4">Regístrate</h1>
      <div className="mb-4">
        <label className="block text-sm font-medium text-white">Nombre</label>
        <input
          type="text"
          className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
          placeholder="Ingresa tu nombre"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-white">Apellido</label>
        <input
          type="text"
          className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
          placeholder="Ingresa tu apellido"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-white">
          Correo Electrónico
        </label>
        <input
          type="email"
          className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
          placeholder="Ingresa tu correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-white">
          Contraseña
        </label>
        <input
          type="password"
          className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
          placeholder="Crea una contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-white">
          Fecha de Nacimiento
        </label>
        <div className="flex gap-2">
          <input
            type="text"
            className="w-1/3 px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
            placeholder="DD"
            value={dayOfBirth}
            onChange={(e) => setDayOfBirth(e.target.value)}
            required
          />
          <input
            type="text"
            className="w-1/3 px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
            placeholder="MM"
            value={monthOfBirth}
            onChange={(e) => setMonthOfBirth(e.target.value)}
            required
          />
          <input
            type="text"
            className="w-1/3 px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
            placeholder="AAAA"
            value={yearOfBirth}
            onChange={(e) => setYearOfBirth(e.target.value)}
            required
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-white">Género</label>
        <input
          type="text"
          className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
          placeholder="Opcional"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        />
      </div>
      <div className="mt-8 flex flex-col gap-y-4">
        <button
          type="submit"
          className="w-full bg-redCTC text-white text-lg font-bold py-2 px-4 rounded-md active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
        >
          Registrarme
        </button>
        <button
          type="button"
          onClick={handleGoogleSignUp}
          className="flex items-center justify-center gap-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out font-semibold rounded-md py-2.5 bg-white"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.26644 9.76453C6.19903 6.93863 8.85469 4.90909 12.0002 4.90909C13.6912 4.90909 15.2184 5.50909 16.4184 6.49091L19.9093 3C17.7821 1.14545 15.0548 0 12.0002 0C7.27031 0 3.19799 2.6983 1.24023 6.65002L5.26644 9.76453Z"
              fill="#EA4335"
            />
            <path
              d="M16.0406 18.0142C14.9508 18.718 13.5659 19.0926 11.9998 19.0926C8.86633 19.0926 6.21896 17.0785 5.27682 14.2695L1.2373 17.3366C3.19263 21.2953 7.26484 24.0017 11.9998 24.0017C14.9327 24.0017 17.7352 22.959 19.834 21.0012L16.0406 18.0142Z"
              fill="#34A853"
            />
            <path
              d="M19.8342 20.9978C22.0292 18.9503 23.4545 15.9019 23.4545 11.9982C23.4545 11.2891 23.3455 10.5255 23.1818 9.81641H12V14.4528H18.4364C18.1188 16.0119 17.2663 17.2194 16.0407 18.0108L19.8342 20.9978Z"
              fill="#4A90E2"
            />
            <path
              d="M5.27698 14.2663C5.03833 13.5547 4.90909 12.7922 4.90909 11.9984C4.90909 11.2167 5.03444 10.4652 5.2662 9.76294L1.23999 6.64844C0.436587 8.25884 0 10.0738 0 11.9984C0 13.918 0.444781 15.7286 1.23746 17.3334L5.27698 14.2663Z"
              fill="#FBBC05"
            />
          </svg>
          Registrarme con Google
        </button>
      </div>
      <div className="mt-4 text-center">
        <p className="text-sm text-white">
          ¿Ya tienes una cuenta?{" "}
          <span
            className="text-redCTC font-semibold cursor-pointer"
            onClick={onSwitchToSignIn}
          >
            Inicia Sesión
          </span>
        </p>
      </div>
    </form>
  );
}
