import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import fondoClub42 from "../imgs/cuartos/club42/fondo_club42.webp";
import logoClub42 from "../imgs/cuartos/club42/logo.png";
import instaImage from "../imgs/cuartos/club42/insta.webp";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";

const InstagramPage = () => {
  const { timeLeft } = useContext(TimerContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center text-white py-12"
      style={{
        backgroundImage: `url(${fondoClub42})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Logo */}
      <div className="my-12">
        <img src={logoClub42} alt="Club 42 Logo" className="w-48 h-auto" />
      </div>

      {/* Imagen principal */}
      <div className="my-8">
        <img
          src={instaImage}
          alt="Instagram Promo"
          className="w-full max-w-2xl h-auto"
        />
      </div>
    </div>
  );
};

export default InstagramPage;
