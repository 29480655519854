import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./stripeConfig";

console.log(
  "Stripe Mode:",
  stripePromise.then((stripe) =>
    console.log(
      stripe._apiKey.startsWith("pk_live_") ? "Production Mode" : "Test Mode"
    )
  )
);

const StripeContainer = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeContainer;
