import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider";
import fondoClub42 from "../imgs/cuartos/club42/fondo_club42.webp";
import logoClub42 from "../imgs/cuartos/club42/logo.png";
import menuImage from "../imgs/cuartos/club42/menu.webp";
import flechaIcon from "../imgs/cuartos/club42/flechamenu.svg";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";
import continueIcon from "../imgs/continue_white.svg";

const Menu = () => {
  const { timeLeft } = useContext(TimerContext);
  const [base, setBase] = useState(""); // Estado para la base seleccionada
  const [flavor, setFlavor] = useState(""); // Estado para el sabor seleccionado
  const [errorMessage, setErrorMessage] = useState(""); // Nuevo estado para el mensaje de error
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handleContinue = async () => {
    if (base === "Tequila" && flavor === "Durazno") {
      try {
        if (currentUser) {
          const userDocRef = doc(db, "club_42", currentUser.uid);
          await updateDoc(userDocRef, { currentPhase: 5 });
        }
        navigate("/dm");
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setErrorMessage("Ocurrió un error. Por favor, intenta de nuevo.");
      }
    } else {
      setErrorMessage("Combinación incorrecta");
    }
  };

  const bases = [
    "Vodka",
    "Mezcal",
    "Tequila",
    "Ron",
    "Whisky",
    "Vino",
    "Licor",
    "Ginebra",
    "Sangría",
  ];
  const flavors = [
    "Mango",
    "Plátano",
    "Fresa",
    "Menta",
    "Pera",
    "Uva",
    "Durazno",
    "Pepino",
    "Manzana",
  ];

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center text-white py-12"
      style={{
        backgroundImage: `url(${fondoClub42})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Logo */}
      <div className="my-12">
        <img src={logoClub42} alt="Club 42 Logo" className="w-48 h-auto" />
      </div>

      {/* Texto principal */}
      <div className="text-center px-8">
        <p className="text-xl md:text-2xl mb-4">
          Te sientas en la barra del bar; acto seguido, el bartender te entrega
          un menú y te dice lo siguiente:
        </p>
        <p className="text-lg italic mb-6">
          "Espero sepas sumar como sabes tocar, pide el único cóctel cuyo precio
          final RESULTE EN EL número especial. Este número lo conocerás si un
          club exclusivo quieres encontrar."
        </p>
      </div>

      {/* Imagen del menú */}
      <div className="mt-8 mb-4">
        <img src={menuImage} alt="Menú" className="w-full max-w-2xl h-auto" />
      </div>

      {/* Texto de advertencia */}
      <div className="text-center px-8">
        <p className="text-sm italic mb-6">
          Este menu no es real, ni esta asociado con ningún establecimiento
          físico.
          <br />
          Los precios y los contenidos en el menú son ficticios y se usan solo
          para fines del Escape Room.
        </p>
      </div>

      {/* Selección de base y sabor */}
      <div className="text-center px-8">
        <p className="text-lg md:text-xl mb-4">
          Por favor dame un{" "}
          <span className="inline-block relative">
            <select
              className="bg-transparent text-white border border-white rounded-md p-2 cursor-pointer appearance-none pr-10"
              value={base}
              onChange={(e) => setBase(e.target.value)}
              style={{
                backgroundImage: `url(${flechaIcon})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 10px center",
              }}
            >
              <option value="" disabled>
                Base
              </option>
              {bases.map((option) => (
                <option
                  key={option}
                  value={option}
                  style={{
                    backgroundColor: "gray", // Fondo negro para las opciones
                    color: "white", // Texto blanco para visibilidad
                  }}
                >
                  {option}
                </option>
              ))}
            </select>
          </span>{" "}
          de{" "}
          <span className="inline-block relative">
            <select
              className="bg-transparent text-white border border-white rounded-md p-2 cursor-pointer appearance-none pr-10"
              value={flavor}
              onChange={(e) => setFlavor(e.target.value)}
              style={{
                backgroundImage: `url(${flechaIcon})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 10px center",
              }}
            >
              <option value="" disabled>
                Sabor
              </option>
              {flavors.map((option) => (
                <option
                  key={option}
                  value={option}
                  style={{
                    backgroundColor: "gray", // Fondo negro para las opciones
                    color: "white", // Texto blanco para visibilidad
                  }}
                >
                  {option}
                </option>
              ))}
            </select>
          </span>
        </p>
      </div>

      {/* Botón de continuar */}
      <div className="mt-6 flex items-center justify-center">
        <button
          className="px-6 py-3 rounded-md text-white flex items-center justify-center font-bold"
          style={{
            background:
              "linear-gradient(90deg, #916002 0%, #EFBE3F 49%, #9D6801 100%)",
          }}
          onClick={handleContinue}
        >
          <img
            src={continueIcon}
            alt="Icono de continuar"
            className="w-6 h-6 mr-2"
          />
          Continuar
        </button>
      </div>

      {/* Mensaje de error */}
      {errorMessage && <div className="text-red-500 mt-4">{errorMessage}</div>}
    </div>
  );
};

export default Menu;
