import React, { useState } from "react";

const daysOfWeek = [
  "LUNES",
  "MARTES",
  "MIERCOLES",
  "JUEVES",
  "VIERNES",
  "SÁBADO",
  "DOMINGO",
];
const days = Array.from({ length: 31 }, (_, i) =>
  (i + 1).toString().padStart(2, "0")
);
const months = Array.from({ length: 12 }, (_, i) =>
  (i + 1).toString().padStart(2, "0")
);

// Genera un arreglo de años desde 1900 hasta 2050
const generateYears = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
};

const years = generateYears(1900, 2050);

const DatePopup = ({
  onSubmit,
  onClose,
  titleText = "Ingresa la Fecha Secreta",
  buttonText = "Verificar Fecha",
  error,
}) => {
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const handleSubmit = () => {
    onSubmit({ dayOfWeek, day, month, year });
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          {titleText}
        </h2>

        <div className="flex flex-col space-y-4">
          {/* Select para día de la semana */}
          <select
            value={dayOfWeek}
            onChange={(e) => setDayOfWeek(e.target.value)}
            className="w-full px-4 py-2 text-black rounded-md"
          >
            <option value="">Día de la Semana</option>
            {daysOfWeek.map((d, i) => (
              <option key={i} value={d}>
                {d}
              </option>
            ))}
          </select>

          {/* Select para día */}
          <select
            value={day}
            onChange={(e) => setDay(e.target.value)}
            className="w-full px-4 py-2 text-black rounded-md"
          >
            <option value="">Día</option>
            {days.map((d, i) => (
              <option key={i} value={d}>
                {d}
              </option>
            ))}
          </select>

          {/* Select para mes */}
          <select
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            className="w-full px-4 py-2 text-black rounded-md"
          >
            <option value="">Mes</option>
            {months.map((m, i) => (
              <option key={i} value={m}>
                {m}
              </option>
            ))}
          </select>

          {/* Select para año */}
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            className="w-full px-4 py-2 text-black rounded-md"
          >
            <option value="">Año</option>
            {years.map((y, i) => (
              <option key={i} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>

        {error && <p className="text-red-500 mt-2">{error}</p>}

        <div className="mt-6">
          <button
            className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center justify-center"
            onClick={handleSubmit}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DatePopup;
