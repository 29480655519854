import React, { useState } from "react";
import FormSignUp from "./ui/formsignup.js";
import SignIn from "./SignIn";

function SignUp({ onClose }) {
  const [showSignIn, setShowSignIn] = useState(false);

  const handleSwitchToSignIn = () => {
    setShowSignIn(true);
  };

  if (showSignIn) {
    return (
      <SignIn
        onClose={() => {
          setShowSignIn(false);
          onClose();
        }}
      />
    );
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black/50 z-50">
      <div className="relative w-full max-w-sm mx-auto p-1 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        {/* Formulario de Registro */}
        <FormSignUp onSwitchToSignIn={handleSwitchToSignIn} />
      </div>
    </div>
  );
}

export default SignUp;
