import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthProvider";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CTCLogo from "../imgs/CTC_Isologo_Original.svg";
import nosotros from "../imgs/icons_header/nosotros-red.svg";
import escaperooms from "../imgs/icons_header/escape-red.svg";
import faqs from "../imgs/icons_header/faqs-red.svg";
import contacto from "../imgs/icons_header/contacto-red.svg";
import HeaderIcon from "./ui/headericon";

import { deleteAccount } from "../firebase"; // La función para eliminar cuenta

import ProfilePopup from "./popUps/profilePopup"; // Importamos el nuevo pop-up
import SignIn from "./SignIn"; // Importa el componente SignIn
import SignUp from "./SignUp"; // Importa el componente SignUp

export default function Header({ menuOpen, setMenuOpen }) {
  const [iconName, setIconName] = useState("menu-outline");
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false); // Estado para el popup de SignIn
  const [isSignUpOpen, setIsSignUpOpen] = useState(false); // Estado para el popup de SignUp

  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar tu cuenta? Esta acción es irreversible."
    );
    if (confirmDelete) {
      await deleteAccount(currentUser);
    }
  };

  const onToggleMenu = () => {
    setIconName((prevName) =>
      prevName === "menu-outline" ? "close-outline" : "menu-outline"
    );
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = async () => {
    await signOut();
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <header className="bg-blueCTC py-4 fixed top-0 w-full z-50">
      <nav className="flex justify-between items-center w-[92%] mx-auto h-10">
        <div className="flex items-center justify-center space-x-4">
          <Link to="/" className="flex items-center justify-center">
            <img src={CTCLogo} alt="CTC Logo" className="h-10 w-10" />
            <span className="sr-only">Escape Rooms</span>
          </Link>
        </div>
        <div
          //Opacidad del menú móvil de momento al 100%
          className={`nav-links lg:static fixed bg-blueCTCmenumovil lg:bg-blueCTC lg:min-h-fit min-h-[35vh] left-0 ${
            menuOpen ? "flex top-[70px] z-50" : "top-[-100%]"
          } lg:w-auto w-full flex items-center px-5 transition-all duration-300`}
        >
          <ul className="flex lg:flex-row flex-col lg:items-center lg:gap-16 xl:gap-40 gap-6 w-full lg:w-auto">
            <li className="lg:border-none border-b border-gray-400 flex items-center justify-start">
              <div className="relative flex items-center">
                <HeaderIcon
                  text="Escape Rooms"
                  route="/"
                  targetId="escapeRooms"
                >
                  <img
                    src={escaperooms}
                    alt="Escaperooms"
                    className="h-10 w-10"
                  />
                </HeaderIcon>
              </div>
            </li>
            <li className="lg:border-none border-b border-gray-400 flex items-center justify-start">
              <div className="relative flex items-center">
                <HeaderIcon text="Sobre Nosotros" route="/aboutUs">
                  <img src={nosotros} alt="Nosotros" className="h-10 w-10" />
                </HeaderIcon>
              </div>
            </li>
            <li className="lg:border-none border-b border-gray-400 flex items-center justify-start">
              <div className="relative flex items-center">
                <HeaderIcon
                  text="Preguntas Frecuentes"
                  route="/faq"
                  targetId="faqSection"
                >
                  <img src={faqs} alt="FAQs" className="h-10 w-10" />
                </HeaderIcon>
              </div>
            </li>
            <li className="lg:border-none border-b border-gray-400 flex items-center justify-start">
              <div className="relative flex items-center">
                <HeaderIcon text="Contacto" targetId="footer">
                  <img src={contacto} alt="Contacto" className="h-10 w-10" />
                </HeaderIcon>
              </div>
            </li>
          </ul>
          <div className="lg:hidden absolute bottom-0 w-[150%] h-1 left-[-4px] bg-redCTC"></div>
        </div>

        <div className="flex items-center gap-6">
          {currentUser ? (
            <>
              <div className="flex items-center gap-2">
                <FontAwesomeIcon
                  icon={faUser}
                  className="h-6 w-6 text-redCTC cursor-pointer"
                  onClick={() => setIsProfileOpen(true)}
                />
                {/* Mostrar el pop-up si el estado isProfileOpen es true */}
                {isProfileOpen && (
                  <ProfilePopup
                    onClose={() => setIsProfileOpen(false)}
                    onDeleteAccount={handleDeleteAccount}
                  />
                )}
              </div>
              <button
                onClick={handleLogout}
                className="bg-gradient-to-b from-redCTC to-redCTCbuttons text-white px-5 py-2 rounded-full font-roboto-mono"
              >
                ¡Crack-Out!
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsSignInOpen(true)} // Abre el popup de SignIn
                className="bg-gradient-to-b from-redCTC to-redCTCbuttons text-white px-5 py-2 rounded-md font-roboto-mono"
              >
                Crack-In
              </button>
              {isSignInOpen && (
                <SignIn onClose={() => setIsSignInOpen(false)} /> // Renderiza el popup de SignIn
              )}
            </>
          )}

          <ion-icon
            onClick={onToggleMenu}
            name={iconName}
            class="text-3xl text-redCTC cursor-pointer lg:hidden z-50"
          ></ion-icon>
        </div>
      </nav>
    </header>
  );
}
