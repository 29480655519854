import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

const ProtectedRoute = ({ requiredPhase, roomId, children }) => {
  const { currentUser } = useAuth();
  const [userPhase, setUserPhase] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser) {
      const userDocRef = doc(db, roomId, currentUser.uid);

      const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          console.log(
            `ProtectedRoute - Fetched currentPhase for ${roomId}:`,
            userData.currentPhase
          );
          setUserPhase(userData.currentPhase || 0);
        } else {
          setUserPhase(0);
        }
        setLoading(false);
      });

      return () => unsubscribe();
    } else {
      setLoading(false);
    }
  }, [currentUser, roomId]);

  if (!currentUser) {
    console.log("ProtectedRoute - No currentUser. Redirecting to /");
    return <Navigate to="/" replace />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (userPhase < requiredPhase) {
    console.log(
      `ProtectedRoute - Insufficient phase (${userPhase} < ${requiredPhase}). Redirecting to /`
    );
    return <Navigate to="/" replace />;
  }

  console.log(
    `ProtectedRoute - Access granted for room ${roomId} at phase ${userPhase}`
  );
  return children;
};

export default ProtectedRoute;
