import React, { useState } from "react";
// import FondoEscapeRooms from "../imgs/Fondo-escaperooms.svg";
// import flechaAbajo from "../imgs/Flecha-abajo.svg";
import hibridos from "../imgs/hibridos.svg";
import virtuales from "../imgs/virtuales.svg";
import personalizados from "../imgs/personalizados/personalizados.svg";
// import { Link } from "react-router-dom";
import FlipCards from "./FlipCards";
import FlipCardsVirtuales from "./FlipCardsVirtuales.js";
import Personalizados from "./personalizados.js";
import Contact from "./contact.js";
import icon_hibri from "../imgs/icon_hibri.svg";
import icon_virtual from "../imgs/icon_virtual.svg";
import icon_perso from "../imgs/icon_perso.svg";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fondoquees from "../imgs/fondo_que_es.png";
import FlightInfoPopUp from "./FlightInfo"; // Cambiado a FlightInfoPopUp

const EscapeRooms = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false); // Estado para controlar si el pop-up está abierto
  const [highlightedCity, setHighlightedCity] = useState(null); // Nueva variable para la ciudad seleccionada
  const [highlightedTerminal, setHighlightedTerminal] = useState(null); // Nueva variable para la terminal seleccionada

  // Función para resaltar la tarjeta basada en la ciudad y terminal seleccionada
  const highlightEscapeRoom = (city, terminal) => {
    setHighlightedCity(city);
    setHighlightedTerminal(terminal);
  };

  return (
    <div className="relative w-full h-full">
      <main
        className={`relative bg-blueCTCbg w-full min-h-[420vh] transition-all duration-300 ${
          menuOpen ? "mt-[30vh]" : "mt-0"
        }`}
      >
        <section
          className="w-full py-24 px-4 md:px-24 flex flex-col items-center justify-center text-center"
          style={{
            backgroundImage: `url(${fondoquees})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        >
          <div className="lg:px-32 2xl:px-64">
            <h2 className="text-4xl md:text-5xl lg:text-6xl text-white font-nebulica font-bold mb-12 md:mb-16 lg:mb-24">
              ¿QUÉ ES UN ESCAPE ROOM?
            </h2>
            <p className="text-sm md:text-lg text-white mb-2 md:mb-4 lg:mb-8 font-roboto-mono">
              Un{" "}
              <span className="font-semibold text-redCTC text-primary">
                escape room
              </span>{" "}
              es un emocionante juego de aventuras donde los jugadores resuelven
              acertijos para alcanzar un objetivo final en un tiempo limitado,
              sumergiéndote en una historia que exige creatividad y pensamiento
              crítico.
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-center mt-16 space-y-8 md:space-y-0 md:space-x-40">
            <div className="flex flex-col items-center">
              <a href="#hibridosinfo">
                <img
                  src={icon_hibri}
                  className="h-24 w-24 lg:h-32 lg:w-32"
                  alt="Híbridos"
                />
              </a>
              <p className="mt-4 text-white text-center font-roboto-mono font-bold">
                Híbridos
              </p>
            </div>
            <div className="flex flex-col items-center">
              <a href="#virtualesinfo">
                <img
                  src={icon_virtual}
                  className="h-24 w-24 lg:h-32 lg:w-32"
                  alt="Virtuales"
                />
              </a>
              <p className="mt-4 text-white text-center font-roboto-mono font-bold">
                Virtuales
              </p>
            </div>
            <div className="flex flex-col items-center">
              <a href="#personalizadosinfo">
                <img
                  src={icon_perso}
                  className="h-24 w-24 lg:h-32 lg:w-32"
                  alt="Personalizados"
                />
              </a>
              <p className="mt-4 text-white text-center font-roboto-mono font-bold">
                Personalizados
              </p>
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center mt-8 lg:mt-16"
            id="hibridosinfo"
          >
            <img
              src={hibridos}
              className="h-32 w-64 md:h-40 md:w-80 lg:h-48 lg:w-96"
              alt="Híbridos"
            />
            <p className="w-3/4 text-sm md:text-lg lg:text-xl text-white mb-12 font-roboto-mono">
              El{" "}
              <span className="font-semibold text-redCTC text-primary italic">
                escape room híbrido
              </span>{" "}
              es una experiencia completamente virtual que requiere que los
              jugadores interactúen con el entorno físico para resolver las
              pistas.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center my-4">
            <button
              onClick={() => setIsPopUpOpen(true)}
              className="bg-gradient-to-b from-redCTC to-redCTCbuttons text-xl text-white px-5 py-2 h-14 w-64 rounded-md font-roboto-mono font-semibold"
            >
              <FontAwesomeIcon icon={faPlane} className="mr-2 -rotate-45" />
              UBICA TU JUEGO
            </button>
          </div>
        </section>
        <section>
          <FlipCards
            highlightedCity={highlightedCity}
            highlightedTerminal={highlightedTerminal}
          />{" "}
          {/* Pasamos la ciudad y terminal */}
        </section>
        <section className="w-full">
          {/* Renderizamos el PopUp solo si está abierto */}
          <FlightInfoPopUp
            isOpen={isPopUpOpen}
            onClose={() => setIsPopUpOpen(false)}
            highlightEscapeRoom={highlightEscapeRoom} // Pasamos la función
          />
          <div
            className="bg-gradient-to-t from-blueCTCbg via-blueCTC to-blueCTCbg pt-24"
            id="virtualesinfo"
          >
            <div className="flex flex-col items-center justify-center md:mb-96 mb-64">
              <img
                src={virtuales}
                className="h-32 w-64 md:h-40 md:w-80 lg:h-48 lg:w-96"
                alt="Virtuales"
              />
              <p className="w-3/4 text-center text-sm md:text-lg text-white mb-2 md:mb-4 lg:mb-8 font-roboto-mono">
                Nuestro{" "}
                <span className="font-semibold text-redCTC text-primary">
                  escape room virtual
                </span>{" "}
                lleva el desafío al ámbito digital, permitiéndote navegar a
                través de enigmas repartidos por la red desde cualquier
                dispositivo inteligente.
              </p>
            </div>
            <div className="-mt-64 md:-mt-96">
              <FlipCardsVirtuales />
            </div>
          </div>
        </section>
        <section className="w-full">
          <div
            className="flex items-center justify-center -mt-12"
            id="personalizadosinfo"
          >
            <img
              src={personalizados}
              className="h-64 w-64 md:h-80 md:w-80 lg:h-96 lg:w-96"
              alt="Personalizados"
            />
          </div>
          <div className="px-4 md:px-24 -mt-16">
            <Personalizados />
          </div>
          <div className="flex items-center justify-center mt-12">
            <button
              onClick={() => setShowContactForm(true)} // Cambia el estado al hacer clic
              className="flex w-64 h-12 items-center justify-center rounded-md bg-gradient-to-b from-redCTC to-redCTCbuttons text-xl text-white font-roboto-mono font-semibold px-6"
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="mr-2 -rotate-45"
              />
              COTIZA EL TUYO
            </button>
          </div>
        </section>
      </main>
      {showContactForm && (
        <div className="fixed inset-0 bg-white/5 flex items-center justify-center z-50">
          <Contact onClose={() => setShowContactForm(false)} />
        </div>
      )}
    </div>
  );
};

export default EscapeRooms;
